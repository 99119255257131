<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
            class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">THK(Korporativ Karta)</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="3" class="pl-0 py-0">
                <v-text-field
                    :error-messages="reg_nomerErrors"
                    v-model="reg_nomer"
                    label="Registratsiya raqami"
                    outlined
                    dense
                    :success="reg_nomer !== ''"
                    @input="$v.reg_nomer.$touch()"
                    @blur="$v.reg_nomer.$touch()"
                ></v-text-field>
              </v-col>

              <v-col cols="2" class="pl-0 py-0">
                <!-- <v-text-field
                  :error-messages="dateErrors"
                  label="Sana"
                  v-mask="'##-##-####'"
                  :success="oper_date !== ''"
                  v-model="oper_date"
                  dense
                  outlined
                  @input="$v.oper_date.$touch()"
                  @blur="$v.oper_date.$touch()"
                ></v-text-field> -->
                <v-menu
                    v-model="sanaMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :error-messages="dateErrors"
                        label="Sana"
                        :success="$v.oper_date.$dirty && oper_date !== ''"
                        :value="oper_date | dateFilter"
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        v-mask="'##-##-####'"
                        @input="onOperDateInput"
                        @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="oper_date"
                      @input="sanaMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col cols="2" class="pl-0 py-0">
                <v-select
                    :error-messages="placement_typeErrors"
                    @change="changed(placement_type)"
                    v-model="placement_type"
                    :items="placement_types"
                    item-text="text"
                    dense
                    :success="
                    placement_type !== '' &&
                    placement_type !== null &&
                    placement_type !== undefined
                  "
                    item-value="value"
                    outlined
                    label="Qayerga"
                    @input="$v.placement_type.$touch()"
                    @blur="$v.placement_type.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="3" class="pl-0 py-0">
                <v-autocomplete
                    :error-messages="corp_cardErrors"
                    v-model="corp_card"
                    :items="getAllCorCards"
                    item-text="name"
                    dense
                    :success="
                    corp_card !== '' &&
                    corp_card !== null &&
                    corp_card !== undefined
                  "
                    item-value="id"
                    outlined
                    return-object
                    label="Karta raqami"
                    @input="$v.corp_card.$touch()"
                    @blur="$v.corp_card.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="pl-0 py-0">
                <v-text-field
                    :value="corp_card.remain | mask"
                    disabled
                    dense
                    outlined
                    label="Karta Summasi"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-autocomplete
                    :error-messages="responsibleErrors"
                    :items="allStuffs"
                    dense
                    v-model="responsible"
                    v-debounce:400ms="myStaff"
                    :success="
                    responsible !== '' &&
                    responsible !== null &&
                    responsible !== undefined
                  "
                    item-text="full_name"
                    item-value="id"
                    label="Masul shaxs"
                    outlined
                    return-object
                    @input="$v.responsible.$touch()"
                    @blur="$v.responsible.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                    :error-messages="seller_to_corpcard_cashErrors"
                    v-model="seller_to_corpcard_cash"
                    label="Sotuvchi"
                    dense
                    outlined
                    :success="
                    seller_to_corpcard_cash !== '' &&
                    seller_to_corpcard_cash !== null &&
                    seller_to_corpcard_cash !== undefined
                  "
                    @input="$v.seller_to_corpcard_cash.$touch()"
                    @blur="$v.seller_to_corpcard_cash.$touch()"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="4" class="py-0">
                <v-autocomplete
                  :items="truckList"
                  v-model="truck"
                  dense
                  item-text="truck_name"
                  item-value="id"
                  label="Mashinani turi"
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" class="pl-0 py-0" v-if="show == 'WH'">
                <v-autocomplete
                    :items="allSklads"
                    v-model="placement_warehouse"
                    item-text="sklad_name"
                    item-value="id"
                    label="Skladlar"
                    dense
                    return-object
                    outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pl-0 py-0" v-if="show == 'EX'">
                <v-autocomplete
                    @change="change1"
                    :items="mainExpenditures"
                    v-model="mainExp"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Asosiy xarajatlar"
                    outlined
                    return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0" v-if="show == 'EX'">
                <v-autocomplete
                    @change="change2"
                    :items="category2"
                    v-model="expCategory"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Kategoriya"
                    outlined
                    return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="py-0" v-if="show == 'EX'">
                <v-combobox
                    :items="category3"
                    v-model="expSet1"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Xarajat turi"
                    outlined
                ></v-combobox>
              </v-col>
              <v-col cols="12" class="pl-0 pb-2">
                <b-tabs content-class="mt-3">
                  <b-tab
                      v-for="tab in allCategoryGroupNames"
                      :key="tab.id"
                      :title="tab.name"
                      @click="getCategorData(tab)"
                  >
                    <v-row align="center">
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-autocomplete
                            v-model="products_services.import_category_id"
                            :items="getCategoryGroupData"
                            item-text="name"
                            item-value="id"
                            label="Nomenklatura"
                            outlined
                            class="mx-1 w-30"
                            return-object
                            dense
                            :loading="isLoading"
                        />

                        <v-autocomplete
                            v-if="
                            truckRequiredCategoryList.includes(
                              +categoryImportCode
                            ) && show == 'EX'
                          "
                            v-model="truck_props"
                            :items="truckList"
                            item-text="truck_computed_name"
                            item-value="id"
                            label="Mashina"
                            outlined
                            class="mx-1 w-30"
                            dense
                            return-object
                        />

                        <v-select
                            v-if="tab.code == '0001'"
                            v-model="product_condition"
                            :items="conditions"
                            item-text="name"
                            item-value="id"
                            label="Holati"
                            outlined
                            class="mx-1"
                            dense
                            style="width: 15%"
                        ></v-select>
                        <v-text-field
                            v-if="
                            products_services.import_category_id !== undefined
                          "
                            :value="
                            products_services.import_category_id
                              .measurement_name
                          "
                            disabled
                            label="o'lchov"
                            outlined
                            class="mx-1 w-20"
                            dense
                        >
                          {{
                            products_services.import_category_id
                                .measurement_name
                          }}
                        </v-text-field
                        >
                        <v-text-field
                            v-model="amount"
                            @change="changeAmount(amount)"
                            label="Soni"
                            outlined
                            dense
                            v-mask="'##########'"
                            class="mx-1"
                            style="width: 10%"
                        ></v-text-field>
                        <v-text-field
                            v-model="price"
                            label="Narxi"
                            outlined
                            v-currency="options"
                            dense
                            class="mx-1"
                        ></v-text-field>
                        <!-- <v-text-field
                          v-model="total"
                          label="Summa"
                          v-currency="options"
                          outlined
                          dense
                          class="mx-1"
                        ></v-text-field> -->
                        <v-text-field
                            v-model="nds"
                            label="nds"
                            placeholder="0"
                            outlined
                            dense
                            class="mx-1"
                            style="width: 10%"
                        ></v-text-field>
                        <v-text-field
                            v-model="ndssumma"
                            label="Nds summa"
                            v-currency="options"
                            outlined
                            dense
                            class="mx-1"
                        ></v-text-field>
                        <v-text-field
                            v-model="total"
                            @change="changeTotalSumm"
                            label="Summa(NDS)"
                            v-currency="options"
                            outlined
                            dense
                            class="mx-1"
                        ></v-text-field>
                        <!-- <v-text-field
                          :value="withNds | mask"
                          label="Summa(NDS bilan)"
                          outlined
                          disabled
                          dense
                          class="mx-1"
                        ></v-text-field> -->
                      </v-col>
                    </v-row>
                  </b-tab>
                </b-tabs>
                <div>
                  <v-row justify="end" class="text-right">
                    <v-col>
                      <v-btn @click="remove" color="error" class="mx-1" dense
                      >O'chirish
                      </v-btn
                      >
                      <v-btn @click="add" dense color="success">Qo'shish</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                    v-if="items.length !== 0"
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    class="elevation-1"
                >
                  <template v-slot:[`item.import_category_id`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.import_category_id.name }}
                    </b>
                    <div v-else>
                      {{ item.import_category_id.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.price | mask }}
                    </b>
                    <div v-else>
                      {{ item.price | mask }}
                    </div>
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.total | mask }}
                    </b>
                    <div v-else>
                      {{ item.total | mask }}
                    </div>
                  </template>
                  <template v-slot:[`item.nds`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.nds | mask }}
                    </b>
                    <div v-else>
                      {{ item.nds | mask }}
                    </div>
                  </template>
                  <template v-slot:[`item.nds_summa`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.nds_summa | mask }}
                    </b>
                    <div v-else>
                      {{ item.nds_summa | mask }}
                    </div>
                  </template>
                  <template v-slot:[`item.total_withnds_summa`]="{ item }">
                    <b v-if="!item.amount">
                      {{ item.total_withnds_summa | mask }}
                    </b>
                    <div v-else>
                      {{ item.total_withnds_summa | mask }}
                    </div>
                  </template>

                  <template v-slot:[`item.truck_full_name`]="{ item }">
                    {{ item.truck_full_name }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                  type="button"
                  :disabled="cardCreateLoading"
                  class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                  @click="save"
              >
                <i v-if="cardCreateLoading" class="el-icon-loading"></i> Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import {CurrencyDirective} from 'vue-currency-input'
import Swal from 'sweetalert2'
import {mapGetters, mapActions} from 'vuex'
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import {required} from 'vuelidate/lib/validators'

import {
  truckRequiredCategoryList,
  permissionCheckKeys
} from '@/constants/js/truck.required.category.js'

export default {
  directives: {currency: CurrencyDirective},
  data() {
    return {
      truckRequiredCategoryList,
      permissionCheckKeys,

      truck: '',
      corp_card: '',
      seller_to_corpcard_cash: '',
      responsible: '',
      cardCreateLoading: false,
      show: false,
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      placement_type: '',
      mainExp: '',
      expCategory: '',
      expSet1: '',
      headers: [
        {
          text: 'Nomenklatura',
          value: 'import_category_id'
        },
        {text: "O'lchov", value: 'import_category_id.measurement_name'},
        {text: 'soni', value: 'amount'},
        {text: 'Narxi', value: 'price'},
        {text: 'Nds', value: 'nds'},
        {text: 'NDS summa', value: 'nds_summa'},
        // { text: 'Summa', value: 'total' },
        {text: 'Summa(NDS)', value: 'total_withnds_summa'},
        {text: 'Mashina', value: 'truck_full_name'}
      ],
      categoryId: null,
      tab: '',
      placement_types: [
        {text: 'Sklad', value: 'WH'},
        {text: 'Xarajat', value: 'EX'}
      ],
      menu2: false,
      products_services: {},
      oper_date: '',
      reg_nomer: '',
      placement_warehouse: '',
      items: [],
      items1: [],
      import_group_id: '',
      amount: '',
      price: '',
      price1: 0,
      total: '',
      total1: 0,
      nds: '',
      nds1: '',
      withNds1: '0',
      withNds2: 0,
      ndssumma: '',
      ndssumma1: 0,
      totalSumma: 0,
      totalNdsSumma: 0,
      totalWithNdsSumma: 0,
      product_condition: '',
      category2: [],
      category3: [],
      payment_source: '',

      sanaMenu: false,
      truckList: [],
      truck_props: {},
      loading: {
        allcategorygroups: false
      }
    }
  },
  validations: {
    oper_date: {
      required
    },
    reg_nomer: {
      required
    },
    corp_card: {
      required
    },
    placement_type: {
      required
    },
    seller_to_corpcard_cash: {
      required
    },
    responsible: {
      required
    }
  },
  created() {
    this.$store.dispatch('getAllCorCards')
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getAllSkladList')
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('getProductCondition')
    this.$store.dispatch('mainExpenditures')
    this.$store.dispatch('serivesPaymentSource')
    this.getCategoryGroupNames()
    this.getMeasurements()
  },
  watch: {
    paymentSource: function (val) {
      // set payment source
      // 0003 code for card option
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.payment_source = val.find((x) => x.code == '0003')
      }
    },
    mainExp: function (val) {
      if ((val !== '', val !== undefined, val !== null)) {
        this.category2 = val.expenditurecategory_set
      }
    },
    expCategory: function (val) {
      if ((val !== '', val !== undefined, val !== null)) {
        this.category3 = val.expendituretype_set
      }
    },
    // amount: function(val) {
    //   // eslint-disable-next-line no-extra-boolean-cast
    //   if (Boolean(val)) {
    //     this.total = (val * this.price1).toLocaleString('es-US')
    //   } else this.total = 1
    // },
    price: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.price1 = parseInt(val.replace(/[ ,]/g, ''), 10)
        console.log('price ---- ', this.price1)
      } else this.price1 = 0
      this.total = (this.amount * this.price1).toLocaleString('es-US')
    },
    total: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.total1 = parseInt(val.replace(/[ ,]/g, ''), 10)
        console.log('total1 --- ', this.total1)
        console.log('nds --- ', this.nds1)
        if (typeof this.nds1 == 'string' && Boolean(this.nds1)) {
          this.ndssumma = '0'
          this.nds1 = parseFloat(this.nds1)
        }
        this.ndssumma =
            (this.total1 / ((100 + this.nds1) / 100)) * (this.nds1 / 100)
        this.ndssumma = Math.round(this.ndssumma).toLocaleString('es-US')
        console.log('ndssumma --- -- ', this.ndssumma)
      } else this.total1 = 0
      // this.ndssumma = ((this.nds1 * this.total1) / 100).toLocaleString('es-US')
    },
    nds: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.nds1 = val
        console.log('nds nds --- -- ', this.nds1)
        if (typeof this.nds1 == 'string') this.nds1 = parseFloat(this.nds1)
        this.ndssumma =
            (this.total1 / ((100 + this.nds1) / 100)) * (this.nds1 / 100)
        this.ndssumma = Math.round(this.ndssumma).toLocaleString('es-US')
        console.log('hisob kitob --- -- ', (this.total1 / ((100 + this.nds1) / 100)) * (this.nds1 / 100))
        console.log('nds-summa nds --- --', this.ndssumma)
      } else {
        this.nds1 = 0
        this.ndssumma = 0
      }
      // this.ndssumma = ((this.nds1 * this.total1) / 100).toLocaleString('es-US')
    },
    ndssumma: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.ndssumma1 = parseInt(val.replace(/[ ,]/g, ''), 10)
        console.log('ndssumma1 --- --', this.ndssumma1)
      } else this.ndssumma1 = 0
    }
  },
  computed: {
    getAllCorCards() {
      const data = this.$store.state.requests.AllCorCards
      if (data.length !== 0) {
        data.forEach((element) => {
          element.name =
              element.owner_name +
              '- ' +
              element.number_16_digit
                  .toString()
                  .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
        })
      }
      return data
    },
    // truckList() {
    //   const data = this.$store.state.requests.allTruckList
    //   data.forEach(x => {
    //     x.truck_name =
    //       x.truck__name + ' ' + x.reg_number_region + ' ' + x.reg_number
    //   })
    //   console.log('000', data)
    //   return data
    // },
    paymentSource() {
      console.log('paymentSource -- -- ', this.$store.state.requests.servicePaymentSource)
      return this.$store.state.requests.servicePaymentSource
    },
    mainExpenditures() {
      console.log('mainExpenditures -- -- ', this.$store.state.requests.mainExpenditures)
      return this.$store.state.requests.mainExpenditures
    },
    conditions() {
      const data = this.$store.state.requests.productCondition
      if (data[0] !== undefined) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.product_condition = data[0].id
      }
      return data
    },
    withNds() {
      console.log('withNds -- -- ', this.total1 / (1 + this.nds1 / 100))
      return this.total1 / (1 + this.nds1 / 100)
    },
    ...mapGetters(['allCategoryGroupNames', 'getCategoryGroupData']),
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          {prefix: this.prefix, suffix: this.suffix}
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
            ? {min: this.valueRange[0], max: this.valueRange[1]}
            : undefined,
        precision: this.precisionEnabled
            ? this.precisionRangeEnabled
                ? {min: this.precisionRange[0], max: this.precisionRange[1]}
                : this.precisionFixed
            : undefined,
        distractionFree: this.distractionFree
            ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
            : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    getMeasurement() {
      console.log('getMeasurement -- -- ', this.$store.state.requests.measurements)
      return this.$store.state.requests.measurements
    },
    isLoading() {
      console.log('isLoading -- -- ', this.$store.state.requests.isLoading)
      return this.$store.state.requests.isLoading
    },
    dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    corp_cardErrors() {
      const errors = []
      if (!this.$v.corp_card.$dirty) return errors
      !this.$v.corp_card.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    responsibleErrors() {
      const errors = []
      if (!this.$v.responsible.$dirty) return errors
      !this.$v.responsible.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    seller_to_corpcard_cashErrors() {
      const errors = []
      if (!this.$v.seller_to_corpcard_cash.$dirty) return errors
      !this.$v.seller_to_corpcard_cash.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    placement_typeErrors() {
      const errors = []
      if (!this.$v.placement_type.$dirty) return errors
      !this.$v.placement_type.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors
      !this.$v.reg_nomer.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      // console.log('data', data)
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.warehouse_responsible !== null) {
            if (element.responsible_description !== null) {
              element.sklad_name =
                  element.name +
                  ' | ' +
                  element.responsible_description.staffpersonal_description
                      .first_name +
                  ' ' +
                  element.responsible_description.staffpersonal_description
                      .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        // console.log(data)
        return data
      } else return data
    },
    allStuffs() {
      return this.$store.state.requests.allStaff
    },

    categoryImportCode() {
      return (
          this.products_services &&
          this.products_services.import_category_id &&
          +this.products_services.import_category_id.code
      )
    },

    truckHasPermission() {
      return (
          this.truck_props.id &&
          this.categoryImportCode &&
          permissionCheckKeys[+this.categoryImportCode] &&
          this.truck_props[permissionCheckKeys[+this.categoryImportCode]]
      )
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  async mounted() {
    if (this.$route.query.edit) {
      await this.$store.dispatch('productDetails', this.$route.query.edit)
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Sklad'},
      {title: 'Tovar xizmarlari kirimi'},
      {title: 'Registratsiya'}
    ])

    this.forReport5().then((res) => {
      const r = (res || []).map((item) => ({
        ...item,
        truck_computed_name: `${item.truck_type.brand.name}-${item.truck_type.model}-${item.reg_number_region} ${item.reg_number}`
      }))
      this.truckList = r
    })
    if (this.$store.state.requests.productDetails) {
      this.corp_card = {}
      this.responsible = {}
      this.payment_source = {}
      this.placement_warehouse = {}
      this.expSet1 = {}
      // console.log(this.$store.state.requests.productDetails, 'PRODUCT_DETAILS');
      const {
        truck,
        corp_card,
        responsible,
        payment_source,
        seller_to_corpcard_cash,
        placement_type,
        oper_date,
        reg_number,
        placement_warehouse,
        placement_expenditure
      } = this.$store.state.requests.productDetails
      this.truck = truck
      this.corp_card.id = corp_card
      this.responsible.id = responsible
      this.payment_source.id = payment_source
      this.seller_to_corpcard_cash = seller_to_corpcard_cash
      this.placement_type = placement_type
      this.oper_date = oper_date
      this.reg_nomer = reg_number
      this.placement_warehouse.id = placement_warehouse
      this.expSet1.id = placement_expenditure
    }
  },
  methods: {
    onOperDateInput(arg) {
      this.$v.oper_date.$touch()
      // console.log('onOperDateInput', arg)

      if (arg.length >= 10) {
        let [day, month, year] = arg.split('-')

        this.oper_date = `${year}-${+month <= 12 ? month : '01'}-${day}`
      }
    },
    changeTotalSumm() {
      if (Boolean(this.price1) && Boolean(this.total1)) {
        this.amount = (this.total1 / this.price1).toFixed(2)
      }
    },
    changeAmount(val) {
      // console.log('changeAmount')
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.total = (val * this.price1).toLocaleString('es-US')
      } else {
        this.total = '0'
      }
    },
    change1() {
      this.expCategory = ''
      this.expSet1 = ''
    },
    change2() {
      this.expSet1 = ''
    },
    ...mapActions(['getCategoryGroupNames', 'getMeasurements', 'forReport5']),
    changed(val) {
      if (val == 'WH') {
        this.show = 'WH'
        this.placement_warehouse = ''
        this.mainExp = ''
        this.expCategory = ''
        this.expSet1 = ''
      } else if (val == 'EX') {
        this.show = 'EX'
        this.placement_warehouse = ''
        this.mainExp = ''
        this.expCategory = ''
        this.expSet1 = ''
      }
      this.$v.placement_type.$reset()
    },
    remove() {
      if (this.items.length == 0) {
        Swal.fire({
          title: 'Xato malumot toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
      if (this.items.length > 2) {
        this.items.reverse()
        this.totalNdsSumma -= this.items[this.items.length - 1].nds_summa
        this.totalSumma -= this.items[this.items.length - 1].total
        this.totalWithNdsSumma -=
            this.items[this.items.length - 1].total_withnds_summa
        this.items[0].nds_summa = this.totalNdsSumma
        this.items[0].total = this.totalSumma
        this.items[0].total_withnds_summa = this.totalWithNdsSumma
        this.items.splice(-1, 1)
        this.items.reverse()
      } else if (this.items.length == 2) {
        this.items.splice(-1, 1)
        this.items.splice(-1, 1)
        this.totalNdsSumma = 0
        this.totalSumma = 0
        this.totalWithNdsSumma = 0
      }
    },
    add() {
      if (!this.corp_card.remain) {
        Swal.fire({
          title: "Ma'lumotlar yuborilmadi!",
          text: "Karta tanlanmagan yoki kartada mablag' yetarli emas!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else if (
          this.corp_card.remain - this.totalWithNdsSumma - this.total1 >=
          0
      ) {
        if (this.nds1 == '') {
          this.nds1 = 0
        }
        this.nds1 = parseInt(this.nds1, 10)
        this.products_services.amount = parseFloat(this.amount)
        this.products_services.price = this.price1
        this.products_services.total = Math.round(this.withNds)
        this.products_services.nds = this.nds1
        this.products_services.nds_per_item = (this.nds1 * this.price1) / 100
        this.products_services.nds_summa = this.ndssumma1
        this.products_services.total_withnds_summa = this.total1

        let truck_full_name = null,
            truck_id = null

        if (
            this.truckRequiredCategoryList.includes(+this.categoryImportCode) &&
            this.show == 'EX'
        ) {
          // check is truck selected
          if (!this.truck_props.id) {
            Swal.fire({
              title: 'Xato malumot toldirilgan',
              text: "Xarajatga to'lovda mashina ko'rsatilishi shart",
              icon: 'error',
              confirmButtonClass: 'btn btn-secondary'
            })
            return
          }

          //
          // check key of truck to access
          //
          if (
              Object.keys(permissionCheckKeys).includes(
                  `${this.categoryImportCode}`
              )
          ) {
            // is truck has access?
            // has_advert = false ?
            if (!this.truckHasPermission) {
              Swal.fire({
                title: 'Xato malumot toldirilgan',
                text: "Mashinaning nomenklatura bo'yicha ruxsati yo'q",
                icon: 'error',
                confirmButtonClass: 'btn btn-secondary'
              })
              return
            }
          }

          truck_id = this.truck_props.id

          truck_full_name = `${this.truck_props.truck_type.brand.name}-${this.truck_props.truck_type.model} ${this.truck_props.reg_number_region} ${this.truck_props.reg_number}`
        }

        if (
            this.products_services.amount !== 0 &&
            this.products_services.price !== 0 &&
            this.products_services.import_category_id !== undefined
        ) {
          this.totalSumma += this.products_services.total
          this.totalNdsSumma += this.products_services.nds_summa
          this.totalWithNdsSumma += this.products_services.total_withnds_summa
          this.items.reverse()
          if (this.import_group_id == '') {
            this.import_group_id = this.allCategoryGroupNames[0].id
          }
          this.products_services.import_group_id = this.import_group_id
          this.products_services.product_condition_id = this.product_condition
          if (this.items.length == 0) {
            const data = {
              amount: '',
              import_category_id: {},
              import_group_id: '',
              nds: '',
              nds_per_item: '',
              nds_summa: '',
              price: '',
              total: '',
              total_withnds_summa: 0
            }
            this.items.push(data)
          }
          console.log('list', this.items, this.products_services)
          let tempObject = {
            ...this.products_services
          }

          if (truck_id) {
            tempObject = {
              ...this.products_services,
              truck_full_name,
              truck_id
            }
          }
          this.items.push(tempObject)
          console.log('list1', this.items, this.products_services)
          this.items.reverse()
          console.log('list2', this.items, this.products_services)
          this.items[this.items.length - 1].import_category_id.name =
              'Umumiy summa:'
          this.items[this.items.length - 1].nds_summa = this.totalNdsSumma
          this.items[this.items.length - 1].total = this.totalSumma
          this.items[this.items.length - 1].total_withnds_summa =
              this.totalWithNdsSumma
          this.price1 = 0
          this.amount = 0
          this.total1 = 0
          this.ndssumma1 = 0
          this.nds1 = 0
          this.amount = ''
          this.price = ''
          this.total = ''
          this.nds = ''
          this.ndssumma = ''
          this.products_services = {}
        } else {
          Swal.fire({
            title: 'Xato malumot toldirilgan',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      } else {
        Swal.fire({
          title: "Ma'lumotlar yuborilmadi!",
          text: "Karta tanlanmagan yoki kartada mablag' yetarli emas!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
    getCategorData(value) {
      if (value.code == '0001') {
        if (
            this.conditions != 0 &&
            this.conditions[0] &&
            this.conditions[0].id
        ) {
          this.product_condition = this.conditions[0].id
        }
      } else {
        this.product_condition = null
      }
      this.import_group_id = value.id
      this.price1 = 0
      this.amount1 = 0
      this.total1 = 0
      this.ndssumma1 = 0
      this.nds1 = 0
      this.amount = ''
      this.price = ''
      this.total = ''
      this.nds = ''
      this.ndssumma = ''
      this.products_services = {}
      this.$store.dispatch('getCategoryGroupData', value.id)
    },
    myStaff(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('getStaffList', data)
    },
    filterDate(val) {
      if (val) {
        return val.split('-').reverse().join('-')
      } else return val
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        if (this.placement_type == 'WH') {
          this.expSet1 = {}
          this.expSet1.id = null
        } else {
          this.placement_warehouse = {}
          this.placement_warehouse.id = null
        }
        if (this.placement_warehouse == null) {
          this.placement_warehouse = {}
          this.placement_warehouse.id = null
        }
        const data = {
          document: {
            truck: this.truck,
            corp_card: this.corp_card.id,
            responsible: this.responsible.id,
            payment_source: this.payment_source.id,
            seller_to_corpcard_cash: this.seller_to_corpcard_cash,
            placement_type: this.placement_type,
            oper_date: this.oper_date,
            reg_number: this.reg_nomer,
            placement_warehouse: this.placement_warehouse.id,
            placement_expenditure: this.expSet1.id
          },
          products_services: []
        }
        if (this.items.length !== 0) {
          this.items.splice(-1, 1)
          this.items.forEach((x) => {
            x.import_category_id = x.import_category_id.id
          })
        console.log(this.items, 'props');
          data.products_services = [...this.items].map(
              // eslint-disable-next-line no-unused-vars
              ({truck_full_name, truck_computed_name, ...props}) => props
          )
          this.cardCreateLoading = true
          if (this.$route.query.edit) {
            this.$store
                .dispatch('editGoodsService3', {id: this.$route.query.edit, data})
                .then(() => {
                  this.cardCreateLoading = false
                })
                .catch((err) => {
                  this.cardCreateLoading = false
                  console.error(err)
                  Swal.fire({
                    title: '',
                    text: "Ma'lumotlar o'zgartirilmadi!",
                    icon: 'error',
                    confirmButtonClass: 'btn btn-secondary'
                  })
                })
          } else {
            this.$store
                .dispatch('createGoodsservices3', data)
                .then(() => {
                  this.cardCreateLoading = false
                })
                .catch((err) => {
                  this.cardCreateLoading = false
                  console.error(err)
                  Swal.fire({
                    title: '',
                    text: "Ma'lumotlar yuborilmadi!",
                    icon: 'error',
                    confirmButtonClass: 'btn btn-secondary'
                  })
                })
          }
          this.$v.$reset()
        } else {
          Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.w-30 {
  width: 32%;
}

.w-20 {
  width: 20%;
}

.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}

.payment__name {
  font-weight: bold;
}

.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
