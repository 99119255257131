const truckRequiredCategoryList = [
  1000, 1002, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000
]

const permissionCheckKeys = {
  1000: 'has_insurance',
  2000: 'has_advert',
  9000: 'has_license'
}

export { truckRequiredCategoryList, permissionCheckKeys }
